import { generateReactiveFields } from './utils';
import * as main from './main';
import * as auth from './auth';

export const vars = {
  ...main.vars,
  ...auth.vars
};

export const reactiveFields = generateReactiveFields(vars);
