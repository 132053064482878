export enum FORM_PAGES {
  FORM_SELECT = 'FORM_SELECT',
  PRODUCTS = 'PRODUCTS',
  PRODUCT_CUSTOMIZATIONS = 'PRODUCT_CUSTOMIZATIONS',

  BOOKING_CALENDAR_BOOKING_TYPE = 'BOOKING_CALENDAR_BOOKING_TYPE',
  BOOKING_CALENDAR_DATE_AND_TIME = 'BOOKING_CALENDAR_DATE_AND_TIME',
  BOOKING_CALENDAR_DATE_AND_TIME_REPEATITION = 'BOOKING_CALENDAR_DATE_AND_TIME_REPEATITION',

  CREATE_ACCOUNT_EMAIL = 'CREATE_ACCOUNT_EMAIL',
  CREATE_ACCOUNT_NAME = 'CREATE_ACCOUNT_NAME',
  CREATE_ACCOUNT_POSTCODE = 'CREATE_ACCOUNT_POSTCODE',
  CREATE_ACCOUNT_CODE = 'CREATE_ACCOUNT_CODE',
  CREATE_ACCOUNT_CREATED = 'CREATE_ACCOUNT_CREATED',

  CREATE_PET_TYPE = 'CREATE_PET_TYPE',
  CREATE_PET_CREATE_PET = 'CREATE_PET_CREATE_PET',
  CREATE_PET_GENDER = 'CREATE_PET_GENDER',
  CREATE_PET_BREED = 'CREATE_PET_BREED',
  CREATE_PET_DATE = 'CREATE_PET_DATE',
  CREATE_PET_NEUTRED = 'CREATE_PET_NEUTRED',
  CREATE_PET_ADD_PET = 'CREATE_PET_ADD_PET',

  BOOKING_SELECT_PETS = 'BOOKING_SELECT_PETS',
  BOOKING_FORMS = 'BOOKING_FORMS',
  BOOKING_SUMMARY = 'BOOKING_SUMMARY',
  FORM_FILL_SUMMARY = 'FORM_FILL_SUMMARY',
  BOOKING_ORDER = 'BOOKING_ORDER',
  FORMS_FILLED = 'FORMS_FILLED',
  BOOKING_HANDLING = 'BOOKING_HANDLING',
  BROWSER_NOT_SUPPORTED = 'BROWSER_NOT_SUPPORTED'
}
