import { useEffect, useState } from 'react';
const inWindow = typeof window !== 'undefined';

export function checkLocalStorageSupport() {
  try {
    if (!inWindow) {
      throw new Error();
    }

    localStorage.setItem('test', 'test');
    const item = localStorage.getItem('test');
    if (item === null) {
      throw new Error();
    }

    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
}

export default function useCheckLocalStorageSupport() {
  const [isLocalStorageSupported, setIsLocalStorageSupported] = useState(true);

  useEffect(() => {
    setIsLocalStorageSupported(checkLocalStorageSupport());
  }, []);

  return isLocalStorageSupported;
}
