import { generateVars } from './utils';

const initialStates: {
  isLoggedIn: boolean;
  lastEmailResend: number;
} = {
  isLoggedIn: false,
  lastEmailResend: 0
};

export const persistedVarsStorageKeys: (keyof typeof initialStates)[] = ['isLoggedIn', 'lastEmailResend'];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
