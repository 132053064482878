import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, concat } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { persistCache } from 'apollo3-cache-persist';
import { uncrunch } from 'graphql-crunch';
import 'isomorphic-fetch';
import jwtDecode from 'jwt-decode';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { reactiveFields } from '../reactive';
import AppStorage from './AppStorage';
import Notifier from './Notifier';
import { logout } from './auth';
import config from './config';
import { RefreshToken } from './queries';

const inWindow = typeof window !== 'undefined';
const isProd = config.ENV === 'production';

const subClient = inWindow
  ? new SubscriptionClient(config.SUB_API_URL, {
      reconnect: true
    })
  : null;
const AppStorageMock = { getItem: () => '{}', setItem: () => {} };
const httpLink = new HttpLink({ uri: config.API_URL });
const uncruncher = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    if (response.data && isProd && false) {
      response.data = uncrunch(response.data);
    }
    return response;
  })
);
const inflatedHttpLink = concat(uncruncher, httpLink);
const wsLink = inWindow ? new WebSocketLink(subClient) : null;
const splitLink = inWindow
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      inflatedHttpLink
    )
  : null;

let lastRefreshTime = 0,
  isRefreshing = false,
  isConnected = true;

if (inWindow) {
  window.addEventListener('online', updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);
}

function updateOnlineStatus(event) {
  isConnected = navigator.onLine;
  if (isConnected) {
    Notifier.success({ title: 'Network Status', message: 'Back online' });
  } else {
    Notifier.error({
      title: 'Network Error',
      message: 'Please check your internet connection!'
    });
  }
}

export function isTokenExpired(token: string) {
  const { exp } = jwtDecode(token);
  // console.log(Math.floor(Date.now() / 1000) - (exp - 5))
  return Math.floor(Date.now() / 1000) >= exp - 5; //3580
}

export async function newRefreshToken(client: ApolloClient<any>, tokens: { refreshToken: string }) {
  lastRefreshTime = Date.now();
  const newTokens = await client.mutate({
    mutation: RefreshToken,
    variables: {
      refreshToken: tokens.refreshToken,
      clientId: config.CLIENT_ID,
      slotsv2: true,
      ...(isProd ? { 'x-deduplication': 'true' } : {})
    }
  });
  return newTokens;
}

const authLink = setContext(request => {
  return new Promise(async resolve => {
    if (!isConnected) {
      await retryAfter(500);
    }
    let tokens = AppStorage.get('tokens'); //todo move to redux
    const name = request.operationName;
    const withinRefreshTime = () => lastRefreshTime < Date.now() - 120000;
    const needRefreshToken = tokens => tokens && isTokenExpired(tokens.token) && name !== 'refreshToken' && withinRefreshTime();
    if (needRefreshToken(tokens)) {
      try {
        isRefreshing = true;
        const {
          data: {
            refreshToken: { token, refreshToken }
          }
        } = await newRefreshToken(client, tokens);

        console.log('new token: ', token);
        AppStorage.set('tokens', { token, refreshToken });
        tokens = { token, refreshToken, firebaseToken: tokens.firebaseToken };
        isRefreshing = false;
      } catch (e) {
        isRefreshing = false;
        if (e && e.message.includes('Network error')) {
          return Notifier.error({
            title: 'Network error',
            message: 'Please check your internet connection.'
          });
        } else if (e?.graphQLErrors?.[0]?.message === 'Unauthenticated' || e?.graphQLErrors?.[0]?.message === 'Wrong refresh token') {
          console.warn('about to log out');
          logout();
        }
        console.log(JSON.stringify(e));
      }
    } else if (isRefreshing && name !== 'refreshToken') {
      await retryAfter(500, needRefreshToken);
      tokens = AppStorage.get('tokens');
    }

    return resolve({
      headers: {
        authorization: tokens ? `Bearer ${tokens.token}` : null,
        clientid: config.CLIENT_ID,
        slotsv2: true,
        ...(isProd ? { 'x-deduplication': 'true' } : {})
      }
    });
  });
});

const retryLink = inWindow
  ? new RetryLink({
      delay: {
        initial: 5000,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 5,
        retryIf: (error, _operation) => {
          return true;
          // const message = stripMessage(error);
          // return message.includes('Load failed') || message.includes('Network error');
        }
      }
    })
  : null;

const errorLink = onError(error => {
  let message = 'Something went wrong!';
  try {
    message = stripMessage(error);
  } catch (e) {}
  if (message.includes('too many connections') || message.includes('429')) {
    message = 'We are experiencing heavy traffic please try again after some time.';
  }
  if (message !== 'Unauthenticated' && message !== 'Wrong refresh token' && !message.includes('JSON Parse error') && !message.includes('Unexpected token < in JSON')) {
    Notifier.error({ title: 'Request Error', message: message });
  }
});

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: reactiveFields
    }
  }
});
persistCache({
  cache,
  storage: inWindow ? AppStorage : AppStorageMock,
  maxSize: 262144
});

const client = new ApolloClient({
  cache,
  link: inWindow ? errorLink.concat(retryLink.concat(authLink.concat(splitLink))) : inflatedHttpLink
});

export default client;

const retryAfter = (seconds: number, needRefreshToken = () => false) => {
  console.log('--- Applying Expiremantal Retry After Algo ---');
  return new Promise(resume => {
    const interval = setInterval(async () => {
      let tokens = AppStorage.get('tokens');
      if (isConnected && !isRefreshing && !needRefreshToken(tokens)) {
        clearInterval(interval);
        resume();
      }
    }, seconds);
  });
};

function stripMessage(error: ErrorResponse): string {
  return String(error?.networkError?.result?.errors?.[0]?.message || error?.response?.errors?.[0]?.message || error?.networkError?.message || error?.graphQLErrors?.[0]?.message).replace('Error:', '');
}
