const safeWindow = typeof window === 'undefined' ? null : window;

function AppStorage() {
  function set(key: string, value: any) {
    safeWindow?.localStorage?.setItem(key, JSON.stringify(value));
  }

  function get(key: string) {
    const value = safeWindow?.localStorage?.getItem(key);
    if (value === 'undefined' || value === null) {
      return null;
    }
    return JSON.parse(value || 'null');
  }

  function remove(key: string) {
    safeWindow?.localStorage?.removeItem(key);
  }

  function setItem(key: string, value: any) {
    safeWindow?.localStorage?.setItem(key, btoa(unescape(encodeURIComponent(value))));
  }

  function getItem(key: string) {
    const value = safeWindow?.localStorage?.getItem(key);
    return value ? decodeURIComponent(escape(atob(value))) : 'null';
  }

  function removeItem(key: string) {
    safeWindow?.localStorage?.removeItem(key);
  }

  return { set, get, remove, getItem, setItem, removeItem };
}

export default AppStorage();
