import { ReactNotificationOptions, store } from 'react-notifications-component';

const Notifier = function () {
  const defaultSettings: ReactNotificationOptions = {
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__fadeIn'],
    animationOut: ['animate__fadeOut'],
    dismiss: {
      duration: 6000,
      onScreen: true,
      waitForAnimation: true
    }
  };
  const _error = (props: Partial<ReactNotificationOptions>) =>
    store.addNotification?.({
      ...defaultSettings,
      ...props,
      type: 'danger'
    });
  const _success = (props: Partial<ReactNotificationOptions>) =>
    store.addNotification?.({
      ...defaultSettings,
      ...props,
      type: 'success'
    });
  const _warn = (props: Partial<ReactNotificationOptions>) =>
    store.addNotification?.({
      ...defaultSettings,
      ...props,
      type: 'warning'
    });
  const _info = (props: Partial<ReactNotificationOptions>) =>
    store.addNotification?.({
      ...defaultSettings,
      ...props,
      type: 'info'
    });
  const _remove = () => store.removeNotification();

  const _default = (props: Partial<ReactNotificationOptions>) =>
    store.addNotification?.({
      ...defaultSettings,
      ...props,
      type: 'default'
    });

  return {
    error: _error,
    success: _success,
    warn: _warn,
    info: _info,
    default: _default,
    remove: _remove
  };
};
export default Notifier();
