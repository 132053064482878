import AppStorage from './AppStorage';
import firebase from 'gatsby-plugin-firebase';
import client from './client';
import { vars } from '../reactive';
import { FORM_PAGES } from '../components/Form/types';
import { setFormPage } from '../reactive/actions';
import { checkLocalStorageSupport } from '../hooks/useCheckLocalStorageSupport';

export const setLoginTokens = tokens => {
  AppStorage.set('tokens', tokens);
};

export const logout = async () => {
  const userId = firebase?.auth?.()?.currentUser?.uid;
  try {
    if (firebase.messaging.isSupported()) {
      await firebase?.messaging().deleteToken();
    }
    await firebase?.firestore().collection('Users').doc(userId).set({ tokens: [] });
    await firebase?.auth?.().signOut();
  } catch (e) {
    // user signed in from elsewhere
    console.error('FIREBASE ERROR ON LOGOUT:', e);
  }
  AppStorage.remove('tokens');
  AppStorage.remove('apollo-cache-persist');
  client.clearStore();
  client.cache.reset();
  vars.isLoggedIn(false);
  setTimeout(() => {
    if (checkLocalStorageSupport()) {
      if (vars.isFormFillView()) {
        setFormPage(FORM_PAGES.FORM_SELECT);
        return;
      }
      setFormPage(FORM_PAGES.PRODUCTS);
    } else {
      setFormPage(FORM_PAGES.BROWSER_NOT_SUPPORTED);
    }
  });
};
