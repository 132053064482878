import { vars } from '.';
import { FORM_PAGES } from '../components/Form/types';

const pages = Object.values(FORM_PAGES);

export const increaseFormPage = () => {
  vars.formClass('fade');
  const currentPageIndex = pages.indexOf(vars.formPage());
  const nextPage = pages[currentPageIndex + 1];

  setTimeout(() => {
    vars.formPage(nextPage);
  }, 50);
};

export const decreaseFormPage = () => {
  vars.formClass('fade-out');
  const currentPageIndex = pages.indexOf(vars.formPage());
  const nextPage = pages[currentPageIndex - 1];
  setTimeout(() => {
    vars.formPage(nextPage);
  }, 50);
};

export const setFormPage = (page: FORM_PAGES, animation?: 'fade' | 'fade-out', params?: {}) => {
  const currentPageIndex = pages.indexOf(vars.formPage());
  const newPageIndex = pages.indexOf(page);
  const newPage = pages[newPageIndex];

  if (animation) {
    vars.formClass(animation);
  } else {
    newPageIndex > currentPageIndex ? vars.formClass('fade') : vars.formClass('fade-out');
  }

  setTimeout(() => {
    vars.formPage(newPage);
    vars.navigationParams(params || {});
  }, 50);
};
