const config = {
  apiKey: 'AIzaSyB0ZeeFLK2tPGNv09ceqVDiHVY3_FFa_YE',
  authDomain: 'collar-dev.firebaseapp.com',
  databaseURL: 'https://collar-dev.firebaseio.com',
  projectId: 'collar-dev',
  storageBucket: 'collar-dev.appspot.com',
  messagingSenderId: '533424689809',
  appId: '1:533424689809:web:c98c31c3482a0d07842530',
  measurementId: 'G-VS05YTXLQX'
};

module.exports = config;
